.vu_login_container {
    display: flex;
    padding: 0rem !important;
    margin: 0rem !important;
    position: absolute;
    z-index: 11;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-y: hidden;
}

.vu_logo {
    width: 12rem
}

.vu_login_fields {
    color: #2e3191;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 0.8rem;
    margin: 2rem;
    box-shadow: 2px 4px 8px #0005;
}

/* all rows but last */

.vu_login_fields>div:not(:last-child) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    margin: 0.5rem 0rem;
}

/* first row */

.vu_login_fields>div:nth-child(1) {
    font-weight: 500;
}

/* second row */

.vu_login_fields>div:nth-child(2) {
    font-weight: 700;
}

/* fourth row */

.vu_login_fields>div:nth-child(4) {
    font-weight: 500;
    justify-content: flex-start;
    margin-left: 20%;
}

.vu_login_fields>div:nth-child(4)>span:nth-child(2) {
    white-space: pre;
    color: gray;
    font-weight: 400;
}

/*sixth row*/

.vu_login_fields>div:nth-child(6) {
    color: rgb(248, 58, 121);
    text-decoration: underline;
}

.login_input {
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    border-color: #2e3191;
    font-weight: 800;
    width: 80%;
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
    color: #2e3191;
}

.login_input:disabled {
    color: #2e3191;
}

.login_input::placeholder {
    color: #babbce;
}

.login_btn {
    background-color: #2e3191;
    border-style: none;
    color: white;
    padding: 0.5rem 1rem;
    min-width: 8rem;
    border-radius: 2rem;
}

.cancel_btn {
    border-style: solid;
    border-width: 2px;
    border-color: #2e3191;
    color: #2e3191;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    min-width: 8rem;
}

.login_close {
    cursor: pointer;
    width: 1rem;
    position: absolute;
    margin-left: calc(-100% + 4rem);
}

.close_button {
    cursor: pointer;
    width: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem
}

@media screen and (max-width:1023px) {
    .login_input {
        border-radius: 1px;
    }
    .vu_login_fields {
        border-radius: 0px;
    }
    .login_btn {
        border-radius: 0.2rem;
    }
    .cancel_btn {
        border-radius: 0.2rem;
    }
    /* fourth row */
    .vu_login_fields>div:nth-child(4) {
        justify-content: center;
        margin-left: 0%;
    }
    .vu_login_fields>div:nth-child(4)>span:nth-child(1) {
        display: none;
    }
    .vu_login_fields>div:nth-child(4)>span:nth-child(2) {
        color: #2e3191;
        font-weight: 400;
    }
}