.theball_container {
    padding: 0px !important;
    margin: 0px !important;
    width: calc(100vw - 16px) !important;
    height: auto;
    overflow-x: hidden;
    background-size: 200vw !important;
    background-repeat: no-repeat;
    background-position: center;
}

.theball_title {
    align-items: center;
    padding: 5rem 0rem 1rem 0rem;
    flex-direction: column;
    text-transform: uppercase;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    overflow-x: hidden;
    color: #3a2f8c
}

.theball_statement {
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: x-large;
    font-weight: 600;
    color: #3a2f8c;
    overflow-x: hidden;
    padding: 1rem 0rem;
}

.theball_image {
    width: 100%;
    padding: 1rem;
}

.theball_image_group {
    justify-content: space-around;
    margin: 3rem 0rem
}

.theball_image_group:nth-child(even) {
    flex-direction: row-reverse;
}

.theball_image_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #3a2f8c;
    font-size: x-large;
    font-weight: 700;
}

.theball_chef_image_title {
    font-weight: 800;
    margin-top: 0.5rem;
}

.theball_chef_image_bg {
    background-size: 200vw !important;
    background-repeat: no-repeat;
    background-position: center;
}

.event_time {
    text-align: right;
    display: flex;
    width: 100%;
    font-size: large;
    font-weight: 700;
    color: #3a2f8c;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: top;
    padding: 0rem 5rem 0rem 5rem !important;
}

.event_name {
    font-size: large;
    font-weight: 700;
    color: #3a2f8c;
}

.event_desc {
    font-style: italic;
}

@media screen and (max-width:1023px) {
    .theball_title {
        font-size: x-large;
    }

    .theball_statement {
        font-size: large;
    }

    .theball_image_title {
        font-size: medium;
    }

    .theball_container {
        background-size: 300vw !important;
        width: 100vw !important;
    }

    .theball_chef_image_bg {
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:991px) {
    .theball_container {
        background-size: 400vw !important;
    }
}

@media screen and (max-width:767px) {
    .theball_title {
        font-size: large;
    }

    .theball_statement {
        font-size: medium;
    }

    .theball_image_title {
        font-size: small;
    }

    .theball_container {
        background-size: 450vw !important;
    }

    .event_time {
        padding: 0rem 2rem !important;
    }
}

@media screen and (max-width:566px) {
    .theball_title {
        font-size: medium;
    }

    .theball_container {
        background-size: 600vw !important;
    }
}

@media screen and (max-width:374px) {
    .theball_title {
        font-size: medium;
    }

    .theball_container {
        background-size: 800vw !important;
    }

    .event_time {
        padding: 0rem 1rem !important;
    }
}