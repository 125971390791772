.home_container {
    padding: 0px !important;
    margin: 0px !important;
    width: calc(100vw - 16px) !important;
    height: auto;
    overflow-x: hidden !important;
    background-size: 200vw !important;
    background-repeat: no-repeat;
    background-position: center;
}

.home_title {
    align-items: center;
    padding: 5rem 0rem;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: xx-large;
    color: white
}

.home_description {
    align-items: center;
    padding: 3rem 0rem;
    flex-direction: column;
    text-align: center;
    font-size: x-large;
    color: white
}

.home_banner {
    align-items: center;
    flex-direction: column;
}

.home_page_image {
    width: 100%;
}

@media screen and (max-width:1023px) {
    .home_title {
        font-size: x-large;
    }

    .home_description {
        font-size: large;
    }

    .home_container {
        background-size: 300vw !important;
        width: 100vw !important;
    }
}

@media screen and (max-width:991px) {
    .home_container {
        background-size: 400vw !important;
    }
}

@media screen and (max-width:767px) {
    .home_title {
        font-size: large;
    }

    .home_description {
        font-size: medium;
    }

    .home_container {
        background-size: 500vw !important;
    }
}

@media screen and (max-width:566px) {
    .home_title {
        font-size: medium;
    }

    .home_container {
        background-size: 600vw !important;
    }
}