.signin_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10.4rem);
}

.signin_image {
    height: calc(90vh - 8rem);
}

.signin_header_bar {
    height: 0.6rem;
    width: 100vw;
    background-color: var(--color-light-pink);
    margin-top: 0.6rem
}

.signin_footer_bar {
    height: 0.6rem;
    width: 100vw;
    background-color: var(--color-pink);
    margin-bottom: 0.6rem
}

.signin_text {
    padding: 4rem;
    font-size: 1.4rem;
    font-style: italic;
    font-weight: 900;
    color: var(--color-pink);
    font-family: "bookmania", serif;
}

.signin_input {
    margin: 1.5rem 4rem;
    width: 100%;
    height: 3rem;
    border-color: var(--color-light-pink) !important;
    border-width: 4px;
    box-shadow: none;
    border-radius: 8px;
    border-style: solid;
}

.btn_container {
    display: flex;
    justify-content: space-between;
    padding: 0rem 4rem;
    color: rgb(255, 31, 124);
}

.sign_in_btn {
    background-color: var(--color-pink);
    box-shadow: none;
    border-style: solid;
    border-radius: 8px;
    color: white;
    padding: 0.3rem 1rem;
    font-weight: 700;
    cursor: pointer;
    border-width: 0px;
}

.case_history_btn {
    background-color: var(--color-pink);
    box-shadow: none;
    border-style: solid;
    border-radius: 8px;
    color: white;
    padding: 0.3rem 1rem;
    font-weight: 700;
    cursor: pointer;
    border-width: 0px;
    margin: 2rem 2rem 4rem 2rem
}

.case_num_input {
    margin: 1.5rem 4rem;
    width: calc(100% - 4rem);
    height: 3rem;
    border-color: var(--color-light-pink) !important;
    border-width: 4px;
    box-shadow: none;
    border-radius: 8px;
    border-style: solid;
}

.case_num_container {
    padding: 1rem 2rem !important;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}

.case_num {
    font-size: 1.4rem;
    font-weight: 900;
    color: var(--color-light-pink);
    font-family: "acumin-pro-condensed", sans-serif;
}

.case_num_text {
    /* padding: 4rem; */
    font-size: 1rem;
    font-style: italic;
    font-weight: 900;
    color: var(--color-pink);
    font-family: "bookmania", serif;
}

.case_details {
    background-color: var(--color-light-pink);
    display: flex;
    align-items: center;
    justify-content: center;
}

.case_detail_row {
    width: 100%;
    margin-bottom: 1rem;
}

.case_detail_row>div:nth-child(1) {
    color: var(--color-pink);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 800;
}

.case_detail_row>div:nth-child(2) {
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.case_history_popup_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0003;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10;
    height: 100vh;
    overflow-y: hidden;
}

.case_history_popup {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
    height: 100%;
}

.case_history {
    padding: 2rem !important;
    height: 90%;
    max-width: 90%;
    overflow-y: auto;
    font-weight: 500;
    font-family: "myriad-pro", sans-serif;
}

.case_history_popup_header {
    background-color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem;
    max-width: 100%;
    margin: 0px !important
}

.case_history_popup_header>span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    width: calc(100% - 1.6rem);
}

.case_history_popup_header>img {
    height: 1.2rem;
    cursor: pointer;
}

@media screen and (max-width: 1023px) {
    .sign_in_btn {
        margin-bottom: 2rem
    }

    .case_num_container {
        align-items: center;
        justify-content: center;
        padding: 0rem 0rem;
    }

    .case_header {
        flex-direction: column-reverse;
    }

    .case_num_input {
        align-items: center;
        justify-content: center;
        margin: 2rem 2rem 1rem 2rem;
    }

    .case_detail_row>div:nth-child(1) {
        align-items: flex-start;
        margin-top: 1rem;
    }

    .case_detail_row>div:nth-child(2) {
        color: white;
        font-size: 1.2rem;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .case_history_popup_header>span {
        font-size: 0.8rem !important;
    }

    .case_history {
        font-size: 0.8rem !important;
    }


    .case_history_popup_container {
        height: 100%;
    }

    .signin_container {
        padding-bottom: 2rem
    }
}