:root {
    --color-yellow: #e8ac4c
}

body {
    overflow: hidden
}

.root_container {
    overflow-y: auto;
    margin-top: 8rem;
    height: calc(100vh - 8rem) !important;
    z-index: 2;
    background-color: white;
    position: absolute;
}

.text-xxl {
    font-size: xx-large;
}

.text-uppercase {
    text-transform: capitalize;
}

.rosella_solid {
    font-family: rosella-solid;
    letter-spacing: 0.1rem;
}

.century_gothic {
    font-family: century-gothic;
}

@media screen and (max-width: 1023px) {
    .root_container {
        overflow-y: auto;
        margin-top: 0rem;
        padding-top: 4rem;
        height: 100% !important;
        z-index: 2;
        background-color: white;
        position: absolute;
    }
}