.header_container {
    position: fixed;
    top: 0;
    height: 8rem;
    background-color: var(--color-pink);
    z-index: 4;
    width: 100vw;
}

.header_logo_container {
    align-items: center;
    justify-content: center;
    height: 100%;
    display: flex;
}

.header_logo {
    margin: 0.5rem;
}

.close_icon_as {
    position: fixed;
    top: 0;
    right: 0;
    height: 2rem;
    width: 2rem;
    z-index: 6;
    padding: 0.3rem;
    margin: 0.5rem;
    cursor: pointer;
}

.menu_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu_icon_container {
    display: flex;
    justify-content: center;
    margin: 1.5vw;
    align-items: center;
}

.menu_icon {
    height: 1.2rem;
}

.header_text {
    justify-content: end;
    margin-right: 4rem !important;
    font-size: x-large;
    font-weight: 900;
    font-style: italic;
    color: var(--color-light-pink);
    margin: 0.5rem;
    font-family: "bookmania",serif;
}

.header_container_mobile {
    display: none;
}

.mobile_menu_as {
    display: none !important;
}

@media screen and (max-width: 1023px) {
    .mobile_header_text {
        justify-content: end;
        font-size: medium;
        font-weight: 900;
        font-style: italic;
        color: var(--color-light-pink);
        font-family: "bookmania",serif;
    }
    .header_container {
        display: none;
    }
    .header_container_mobile {
        display: flex;
        max-height: 6rem;
        height: 100%;
        position: fixed;
        z-index: 3;
        top: 0;
        background-color: var(--color-pink);
    }
    .mobile_header_logo_as {
        width: 100%;
    }
    .header_icon {
        max-width: 3rem;
        cursor: pointer;
    }
    .header_icon_container {
        max-width: 4rem !important;
    }
    .mobile_menu_as {
        background-color: var(--color-pink) !important;
        height: 100vh !important;
        width: 100vw !important;
        position: fixed;
        z-index: 6;
        top: 0;
        overflow: hidden;
        flex-direction: column;
        display: flex !important;
    }
    .mobile_menu_as_header {
        height: 4rem;
    }
    .mobile_menu_as_items {
        height: calc(100% - 4rem);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 3rem;
    }
    .mobile_menu_as_image {
        max-height: 2.5rem;
        margin: 3rem
    }
    .menu_active_as {
        height: 2.5rem !important;
        max-height: 2.5rem !important;
    }
    .close_icon_as {
        display: none;
    }
}