:root {
    --color-pink: #b81f44;
    --color-light-pink: #e9a69d;
}

body {
    overflow: hidden
}

.root_container_as {
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 8rem;
    height: calc(100vh - 8rem) !important;
    z-index: 2;
    background-color: white;
    position: absolute;
    width: 100vw;
}

@media screen and (max-width: 1023px) {
    .root_container_as {
        overflow-y: auto;
        margin-top: 0rem;
        padding-top: 6rem;
        height: 100% !important;
        z-index: 2;
        background-color: white;
        position: absolute;
    }
}