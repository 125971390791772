/* 
div {
    border: 1px solid lightblue
} */

.searchBox {
    background-color: #9696ad;
    border-radius: 60px;
}

.vuheader-middle {
    width: 100%; 
    margin-right: "20px";
    margin-left: "20px";
}

.vuheader
{
    opacity:0.5;
    background-color: #cacacc;
    z-index: 10;
    color: #2E3191
}
.vuheader img
{
    Z-index: 5;
}

.vuheadericons {
    background-image: url("https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-gradiantbg.svg");
    height: 10vh;
    background-size: cover;
    padding: 10px;
}

.belowHeader{
    height: 90vh;
    padding-left: 15px;
    padding-right: 10px;
}

.fileContainer {
   color: #2E3191;
}

.fileContainer div {
    border-bottom: 1px solid #2E3191;
}

.vu-header {
    background-color: #2E3191;
    height: 5vh;
    color: white;
    cursor: move;
}

.vu-txt div, .vu-pdf-div {
    border-bottom: 0px;
}

.vu-pdf-image { 
    overflow-y: auto; 
    height: 60vh;
}

.center {
    border-bottom: 0px !important;
}

.vu-txt { 
    position: absolute;
    top: 2%;
    left: 5%;
    right: 5%;
    border-radius: 5px;
    overflow-y: hidden;
    
    z-index: 1;
    background-color: white;
    border: 2px solid #2E3191;
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.vu-pdf { 
    position: absolute;
    top: 2%;
    left: 2%;
    right: 2%;
    bottom: 2%;
    border-radius: 5px;
    
    z-index: 1;
    background-color: white;
    border: 2px solid #2E3191;
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

@media screen and (max-width:1082px) {

    .vu-pdf  { 
        left: 0%;
        top: 0%;
        bottom: 5%;
        right: 2%;
    }

    .vu-pdf-image { 
        height: 78%;
    }
}

/* blue hex color: #2E3191
typeface: Open Sans */