.whoweare_container {
    padding: 0px !important;
    margin: 0px !important;
    width: calc(100vw - 16px) !important;
    height: auto;
    overflow-x: hidden;
    background-size: 200vw !important;
    background-repeat: no-repeat;
    background-position: center;
}

.whoweare_title {
    align-items: center;
    padding: 5rem 0rem 1rem 0rem;
    flex-direction: column;
    text-transform: uppercase;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    overflow-x: hidden;
    color: white
}

.whoweare_statement {
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: x-large;
    font-weight: 600;
    color: white;
    overflow-x: hidden;
    padding: 1rem 0rem;
}

.whoweare_foundation_image {
    width: 100%;
    padding: 1rem;
}

.whoweare_foundation {
    justify-content: space-around;
    margin: 3rem 0rem
}

.whoweare_foundation_members {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: x-large;
}

.whoweare_foundation_members>.member {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

.whoweare_foundation_members>.member>.name {
    font-weight: 700;
}

.whoweare_foundation_members>.member>.designation {
    margin-bottom: 2rem;
}

@media screen and (max-width:1023px) {
    .whoweare_container {
        background-size: 300vw !important;
        width: 100vw !important;
    }

    .whoweare_title {
        font-size: x-large;
    }

    .whoweare_statement, .whoweare_foundation_members {
        font-size: large;
    }

    .whoweare_foundation_members {
        margin-top: 3rem
    }
}

@media screen and (max-width:991px) {
    .whoweare_foundation_members>.member>.designation {
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width:767px) {
    .whoweare_container {
        background-size: 400vw !important;
    }

    .whoweare_title {
        font-size: large;
    }

    .whoweare_statement, .whoweare_foundation_members {
        font-size: medium;
    }

    .whoweare_foundation_members>.member>.designation {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:566px) {
    .whoweare_container {
        background-size: 600vw !important;
    }

    .whoweare_title {
        font-size: medium;
    }
}