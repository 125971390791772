.header-container {
    background-color: var(--color-yellow);
    max-height: 8rem;
    position: fixed;
    z-index: 6;
    top: 0
}

.menu_items {
    max-height: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.menu_image {
    height: 1rem;
    cursor: pointer;
    min-width: 30%;
}

.menu_active {
    height: 3rem
}

.logo_image_container {
    height: 9rem;
    background-color: var(--color-yellow);
    padding: 0rem 1rem 1rem 1rem;
    margin: 1rem;
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
}

.logo_image {
    height: 8rem;
}

.header-mobile {
    display: none;
}

.mobile_menu {
    display: none !important;
}

.close_icon {
    position: fixed;
    top: 0;
    right: 0;
    height: 2rem;
    width: 2rem;
    z-index: 6;
    padding: 0.3rem;
    margin: 0.5rem;
    cursor: pointer;
}

@media screen and (max-width: 1023px) {
    .header-container {
        display: none;
    }
    .header-mobile {
        display: flex;
        max-height: 4rem;
        height: 100%;
        position: fixed;
        z-index: 6;
        top: 0;
        background-color: var(--color-yellow);
    }
    .mobile_header_logo {
        max-width: 60%;
        padding: 1rem;
        height: 100%;
    }
    .header_icon_tk {
        max-width: 3rem;
        cursor: pointer;
    }
    .header_icon_tk_container {
        max-width: 4rem !important;
    }
    .mobile_menu {
        background-color: var(--color-yellow);
        height: 100% !important;
        width: 100vw !important;
        position: fixed;
        z-index: 6;
        top: 0;
        overflow: hidden;
        flex-direction: column;
        display: flex !important;
    }
    .mobile_menu_header {
        height: 4rem;
    }
    .mobile_menu_items {
        height: calc(100% - 7rem);
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .mobile_menu_image {
        /* max-width: 30%; */
        max-height: 1.5rem;
    }
    .menu_active {
        height: 4rem !important;
        max-height: 4rem !important;
    }
    .close_icon {
        display: none;
    }
}