.thekrews_container {
    padding: 0px 0px 1rem 0px !important;
    margin: 0px !important;
    width: calc(100vw - 16px) !important;
    height: auto;
    overflow-x: hidden;
    background-color: #fff4f4;
}

.thekrews_title {
    align-items: center;
    padding: 5rem 0rem 1rem 0rem;
    flex-direction: column;
    text-transform: uppercase;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    overflow-x: hidden;
    color: #3a2f8c
}

.thekrews_logo_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 0rem
}

.thekrews_logo {
    height: 15rem;
    margin: 2rem 0rem;
}

@media screen and (max-width:1023px) {
    .thekrews_container {
        width: 100vw !important;
    }
}