.landingContainer {
    background-color: #DFDDDE;
    position: relative;
}

.landingLoginBox {
    margin: 0;
    position: absolute;
    background-color: #454545;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    width: 250px;
    transform: translate(-50%, -50%);
}

.error {
    color: red
}

.gray-logo-background {
    background-color: #C1B3D8;
    background-image: url("https://cdn.huntakiller.com/huntakiller/s7/graylogo.svg");
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.hak-contact-icon {
    height: 12vh;
    position: absolute;
    right: 4vh;
    bottom: 7vh;
    z-index: 0
 }

.hak-contact-icon img {
    max-height: 100%;
    height: 100%;
    cursor: pointer;
}

.hak-icon {
    display: flex;
    height: 15vh;
    padding: 0px;
}

.hak-icon-label {
    text-align: center;
    word-wrap: break-word;
}

.hak-icon img {
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.hak-icon-container {
    color: white;
}

.hak-icon-ul{
    margin-right: 0px !important;
}

@media screen and (max-width:800px) {
    
}