.dock-container {
    position: absolute;
    bottom: 0;
    text-align: center;
    height: 5vh;;
    width: 100%;
    background-color: #454545;
    color: #fff;
    border-top:1px solid #333333;
    display: flex;
    z-index: 10;
}

.dock-container a{
    line-height: 5vh;
    padding-left: 5vh;
    text-align: left;
    display:inline-block;
    color: #fff;
}

.dock-container a:hover{
    color: #fff;
}

.dock-container div{
    width: 50%;
    text-align: left;
}

.app-clock{
    line-height: 5vh;
    text-align: right;
    width: 50%;
    padding-right: 5vh;
}