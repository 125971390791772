.findapet_container {}

.findapet_title {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    font-size: xx-large;
    font-weight: 900;
    font-style: italic;
    color: var(--color-light-pink);
    font-family: "bookmania", serif;
}

.pet_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.pet_image {
    width: 90%;
}

.findapet_header_bar {
    height: 0.6rem;
    width: 100vw;
    background-color: var(--color-light-pink);
    margin-top: 0.6rem
}

.findapet_footer_bar {
    height: 0.6rem;
    width: 100vw;
    background-color: var(--color-pink);
    margin-bottom: 0.6rem
}

.findapet_statement {
    padding: 3rem;
    padding-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-pink);
    font-size: 1.8rem;
    font-weight: 1000;
    background-color: var(--color-light-pink);
    text-align: center;
    line-height: 2rem;
    font-family: "acumin-pro", sans-serif;
}

.findapet_mobile_title {
    display: none !important;
}

@media screen and (max-width:1023px) {
    .findapet_mobile_title {
        display: flex !important;
        background-color: var(--color-light-pink);
        color: white;
        font-size: 1.8rem;
        justify-content: center;
        align-items: center;
    }

    .findapet_header_bar {
        display: none !important;
    }

    .findapet_footer_bar {
        display: none !important;
    }
}

@media screen and (max-width:575px) {
    .findapet_mobile_title {
        font-size: 1.5rem;
    }

    .findapet_title {
        font-size: 1.2rem;
    }

    .findapet_statement {
        padding: 1rem;
        padding-bottom: 3rem;
        font-size: 1.1rem;
    }
}