.home_container_as {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100vw;
    padding: 0px !important;
    margin: 0px !important;
}

.home_banner {
    width: 100%
}

.home_statement {
    padding: 3rem;
    padding-bottom: 5rem;
    color: var(--color-pink);
    font-size: 1.8rem;
    font-weight: 1000;
    background-color: var(--color-light-pink);
    line-height: 2rem;
    font-family: "acumin-pro", sans-serif;
}

@media screen and (max-width:1023px) {
    .home_statement {
        padding: 2.5rem;
        padding-bottom: 4rem;
        font-size: 1.6rem;
    }
}

@media screen and (max-width:767px) {
    .home_banner {
        height: 25rem;
        object-fit: cover;
        object-position: -15vw;
    }

    .home_statement {
        padding: 2rem;
        font-size: 1.4rem;
        padding-bottom: 4rem;
    }
}

@media screen and (max-width:576px) {
    .home_banner {
        height: 22rem;
        object-fit: cover;
        object-position: -25vw;
    }

    .home_statement {
        padding: 1.5rem;
        font-size: 1.2rem;
        padding-bottom: 4rem;
    }
}

@media screen and (max-width:374px) {
    .home_banner {
        height: 20rem;
        object-fit: cover;
        object-position: -35vw;
    }

    .home_statement {
        padding: 1rem;
        font-size: 1rem;
        padding-bottom: 4rem;
    }
}