.news_container {
    padding: 0px !important;
    margin: 0px !important;
    width: calc(100vw - 16px)  !important;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff4f4;
}

.news_title {
    align-items: center;
    padding: 5rem 0rem 0rem 0rem;
    flex-direction: column;
    text-transform: uppercase;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    overflow-x: hidden;
    color: #3a2f8c
}

.news_type {
    font-weight: 700;
}

.news_statement:last-of-type {
    margin-bottom: 3rem;
}

.news_statement {
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: x-large;
    font-weight: 600;
    color: #3a2f8c;
    overflow-x: hidden;
}

@media screen and (max-width:1023px) {
    .news_title {
        font-size: x-large;
    }
    .news_statement {
        font-size: large;
    }
    .news_container {
        width: 100vw  !important;
    }
}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {
    .news_title {
        font-size: large;
    }
    .news_statement {
        font-size: medium;
    }
}

@media screen and (max-width:566px) {
    .news_title {
        font-size: medium;
    }
}