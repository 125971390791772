.sponsors_container {
    padding: 0px 0px 0rem 0px !important;
    margin: 0px !important;
    width: calc(100vw - 16px) !important;
    height: auto;
    overflow-x: hidden;
    background-color: #fff4f4;
}

.sponsors_title {
    align-items: center;
    padding: 5rem 0rem 1rem 0rem;
    flex-direction: column;
    text-transform: uppercase;
    text-align: center;
    font-size: xx-large;
    font-weight: 700;
    overflow-x: hidden;
    color: #3a2f8c
}

.sponsors_logo_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 0rem
}

.sponsors_logo {
    width: 40%;
    margin: 2rem 0rem;
}

.sponsors_ad_image_container {
    background-color: var(--color-yellow);
}

.sponsors_ad_image {
    width: 80%;
    padding: 2rem 0;
    margin: auto;
}

@media screen and (max-width:1023px) {
    .sponsors_ad_image {
        width: 100vw;
        margin-top: -3rem !important;
    }

    .sponsors_container {
        width: 100vw !important;
    }
}